<template>
  <v-container fluid class="service-detail-height">
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <template v-if="detail?.customer_relation?.id">
              <tr>
                <th colspan="2" class="font-size-18 py-2">
                  Customer Information
                </th>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-1" width="250">
                  Customer #
                </td>
                <td class="font-weight-600 font-size-18 py-1">
                  <Barcode
                    route="customer.detail"
                    :barcode="detail?.customer_relation?.barcode ?? ''"
                    :id="detail?.customer_relation?.id"
                  />
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Display Name
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.display_name ?? "-" }}
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Company Name
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.company_name ?? "-" }}
                </td>
              </tr>

              <!-- <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Default Contact Person's Email
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.default_person?.primary_email ?? "-" }}
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Default Contact Person's Phone
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.default_person?.primary_phone ?? "-" }}
                </td>
              </tr> -->
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Company Email
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.company_email ?? "-" }}
                </td>
              </tr>

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Company Phone
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  {{ detail?.customer_relation?.company_number ?? "-" }}
                </td>
              </tr>

              <tr>
                <th colspan="2" class="font-size-18" />
              </tr>

              <tr>
                <th colspan="2" class="font-size-18 custom-border-top" />
              </tr>
            </template>
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>

            <tr v-if="detail?.door_type_relation?.id">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Door Type
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span>{{ detail?.door_type_relation?.title }}</span>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Name
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.name">{{ detail.name }}</span>
                <em v-else class="text-muted"> no name </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Door Code/Serial Number
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.serial_number">{{
                  detail.serial_number
                }}</span>
                <em v-else class="text-muted"> no item code </em>
              </td>
            </tr>
            <template>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Length
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.length"
                    >{{ detail.length }}
                    {{ detail.length_unit != 0 ? detail.length_unit : "" }}
                  </span>
                  <em v-else class="text-muted"> no length </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Width
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.width"
                    >{{ detail.width }}
                    {{ detail.width_unit != 0 ? detail.width_unit : "" }}</span
                  >
                  <em v-else class="text-muted"> no width </em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Height
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.height"
                    >{{ detail.height }}
                    {{
                      detail.height_unit != 0 ? detail.height_unit : ""
                    }}</span
                  >
                  <em v-else class="text-muted"> no height </em>
                </td>
              </tr>
              <tr>
                <td
                  class="font-size-18 font-weight-500 py-2 d-flex"
                  width="250"
                >
                  Description
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.description">{{
                    detail.description
                  }}</span>
                  <em v-else class="text-muted"> no description </em>
                </td>
              </tr>
            </template>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 grey lighten-4 pl-4">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <div class="" v-if="detail.services">
            <v-layout class="">
              <v-flex>
                <th colspan="2" class="font-size-18 py-2">Services</th>
              </v-flex>
            </v-layout>
            <template v-if="detail.serviceData.length > 0">
              <v-list-item
                v-for="(service, index) in detail.serviceData"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="
                      service.service_image &&
                      service.service_image.file &&
                      service.service_image.file.url
                    "
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-size-18"
                    ><strong>{{ service.barcode }}</strong> - {{ service.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="false">
                  <v-btn
                    icon
                    v-if="getPermission('door:delete')"
                    v-on:click="deleteMember(service)"
                  >
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
            <em v-else class="text-muted pl-2"> no service </em>
          </div>

          <div
            v-if="false"
            class="mt-4"
            :class="{
              'custom-border-top':
                lodash.isEmpty(detail.warranty_data) === false,
            }"
          >
            <v-row>
              <v-col class="pt-0" md="8">
                <p class="font-size-18 font-weight-700 py-2 pl-1 my-0">
                  Service Summary
                </p>
              </v-col>
              <v-col class="pt-0" md="4">
                <v-select
                  :items="summaryFilter"
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-model="summary"
                  hide-details
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0 custom-appex-filter"
                  item-text="text"
                  item-value="value"
                  v-on:change="getSummary(true)"
                >
                </v-select>
              </v-col>
              <v-col md="6" offset-md="3" v-if="dataLoading">
                <v-progress-linear
                  color="cyan"
                  class="my-20"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
              <v-col md="12" v-else class="text-center">
                <template v-if="series.length > 0">
                  <apexchart
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </template>
                <template v-else
                  ><em class="text-muted font-size-16"
                    >Nothing Found...</em
                  ></template
                >
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "service-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        /* {
          title: "Reference",
          key: "reference",
          child: false,
        }, */
        {
          title: "Service ID",
          key: "barcode",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
        {
          title: "Category",
          key: "category",
          child: "text",
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      },
    },
  },

  components: {
    Barcode,
  },

  methods: {
    getSummary(scrollBottom) {
      const _this = this;
      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/summary",
            data: {
              filter: _this.summary,
            },
          })
          .then(({ data }) => {
            let categories = Object.values(data.categories);
            if (
              _this.lodash.isEmpty(categories) === false &&
              _this.lodash.isArray(categories)
            ) {
              _this.chartOptions = {
                ..._this.chartOptions,
                ...{
                  xaxis: {
                    categories: categories.map((row) => {
                      if (
                        _this.summary == "this_year" ||
                        _this.summary == "previous_year"
                      ) {
                        return moment(row).format("MMMM, YYYY");
                      }
                      return moment(row).format("Do, MMMM, YYYY");
                    }),
                  },
                },
              };

              if (_this.lodash.isEmpty(data.series) === false) {
                if (
                  _this.lodash.isEmpty(data.series.quotation_counted) === false
                ) {
                  _this.series.push({
                    name: "Quotation",
                    data: Object.values(data.series.quotation_counted),
                  });
                }
                if (
                  _this.lodash.isEmpty(data.series.invoice_counted) === false
                ) {
                  _this.series.push({
                    name: "Invoice",
                    data: Object.values(data.series.invoice_counted),
                  });
                }
                if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                  _this.series.push({
                    name: "Job",
                    data: Object.values(data.series.job_counted),
                  });
                }
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
              let objDiv = _this.$refs["sidebarScrollbar"].$el;
              _this.$nextTick(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>
